.faq-main-wrapper {
    background-color: #f2f2f2;
    display: flex;
    flex-direction: column;
    padding: 20px;
    font-family: font3!important;
}

.faq-main-header {
    color: black;
    text-align: center;
}

.card-title-holder h1{
    font-size: 1.23em;
}
.card-main-wrapper {
    color: black;
    padding: 20px;
    margin: auto 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.card-holder {
    border-radius: 10px;
    background-color: #f2f2f2;
    margin-bottom: 10px;
    padding: 10px 15px;
    border: 2px solid black;
    width: 65%;
}

.card-title-holder {
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
}

.content {
    max-height: 0;
    overflow: hidden;
    transition: all 0.5s cubic-bezier(0.1,0.1);
}

.content-show{
    font-size: 1.2em;
    text-align: start;
    height: auto;
    max-height: 999px;
    overflow: hidden;
    transition: all 0.5s cubic-bezier(0.1,0.1);
}

.content-show p {
    margin-top: 10px;
}

/* media query for snmall screen */
/* ===================================== */
@media screen and (max-width: 960px) {
    .faq-main-wrapper {
        background-color: #f2f2f2;
        display: flex;
        flex-direction: column;
        padding: 10px;
        font-family: font3!important;
        
    }

    .faq-main-header {
        color: black;
        width: 90%;
        text-align: center;
    }
    .card-title-holder h1{
        font-size: 1.2em;
    }
    .card-holder {
        width: 95%;
        padding: 10px;
    }
}