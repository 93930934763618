.scrollwrapper {
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
    background: #29465b;
    padding: 10px;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    padding: 5px 0;
    margin-top: 85px;

}

.scrollwrapper h1 {
    font-size: 0.875em;
    font-family: EuclidRegular, sans-serif;
    color: rgb(255, 255, 255);
    -moz-transform:translateX(50%);
    -webkit-transform:translateX(50%);	
    transform:translateX(50%);
    /* Apply animation to this element */	
    -moz-animation: bouncing-text 5s linear infinite alternate;
    -webkit-animation: bouncing-text 5s linear infinite alternate;
    animation: bouncing-text 5s linear infinite alternate;
   }
   /* Move it (define the animation) */
   @-moz-keyframes bouncing-text {
    0%   { -moz-transform: translateX(50%); }
    100% { -moz-transform: translateX(-50%); }
   }
   @-webkit-keyframes bouncing-text {
    0%   { -webkit-transform: translateX(50%); }
    100% { -webkit-transform: translateX(-50%); }
   }
   @keyframes bouncing-text {
    0%   { 
    -moz-transform: translateX(50%); /* Browser bug fix */
    -webkit-transform: translateX(50%); /* Browser bug fix */
    transform: translateX(50%); 		
    }
    100% { 
    -moz-transform: translateX(-50%); /* Browser bug fix */
    -webkit-transform: translateX(-50%); /* Browser bug fix */
    transform: translateX(-50%); 
    }
    
}