@import url('https://fonts.googleapis.com/css2?family=Lato:ital@1&display=swap');


.learningwrapper {
    display: flex;
    justify-content: center;
    padding: 35px;
    background-color: beige;
    padding-bottom: 55px;
    padding-top: 55px;
    margin-bottom: 20px;
    font-family: font4!important;
}

.title-header {
    text-align: center;
}

.title-header h1 {
    width: 35vw;
    padding-bottom: 25px;
    padding-left: 45px;
}
.para-learn-one, .para-learn-two {
    margin-bottom: 35px;
    font-family: 'Lato', sans-serif;
    font-size: 1.1em;
}

/* media query for snmall screen */
/* ===================================== */
@media screen and (max-width: 960px) {
    .learningwrapper {
        flex-direction: column;
    }
    .title-header {
        text-align: center;
    }
    .title-header h1 {
        width: 65vw;
        padding-left: 20px;
    }
}