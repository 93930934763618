
@font-face {
  font-family: "font1";
  src: url("./assets/fonts/BakerSignet.ttf") format("opentype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "font2";
  src: url("./assets/fonts/BakerSignet.ttf") format("opentype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "font3";
  src: url("./assets/fonts/BakerSignet.ttf") format("opentype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "font4";
  src: url("./assets/fonts/BakerSignet.ttf") format("opentype");
  font-weight: normal;
  font-style: normal;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

form input {
  width: 55%;
  padding: 35px 20px;
  margin-bottom: 20px;
}

.apply-main-wrapper {
  text-align: center;
  height: 75vh;
  padding-top: 35px;
  font-family: font4!important;
}

.apply-main-wrapper h1{
  margin-bottom: 20px;
 
}

.enter-message-wrapper{
  margin-bottom: 25px;
}
.message-wrapper {
  margin: 0 auto;
  text-align: center;
}

.top-btn-possition {
  /* position: fixed; */
  bottom: 15px;
  right: 20px;
}

.to-top-btn {
  height: 40px;
  width: 40px;
  border-radius: 50%;
  border: 2px solid #333;
  background-color: #fff;
  color: #333;
  cursor: pointer;
}

.icon-btn-wrapper {
  width: 20px;
}

/* media query for snmall screen */
/* ===================================== */
@media screen and (max-width: 960px) {
  .top-btn-possition {
    /* position: fixed; */
    bottom: 15px;
    right: 90px;
  }
}