.why-content-main-wrapper{
    /* position: relative; */
    height: 10vh;
    display:flex;
    justify-content: center;
    margin-bottom: 80px;
}

.over-all-wrapper {
    text-align: center;
}

.over-all-title {
    margin-bottom: 40px;
    font-family: font3!important;
    font-size: 1.53em;
}

.last-message {
    /* margin-top: 20%; */
    margin-bottom: 40px;
}
.css-1ex1afd-MuiTableCell-root {
    background-color: #29465b;
    color: #fff !important;
    font-family: font1!important;
    font-size: 1em;
}

.css-15wwp11-MuiTableHead-root {
    background-color: #29465b;
}


.css-1ygcj2i-MuiTableCell-root {
    color: #fff !important;
    font-size: 1.3em;
}

.what-main-title {
    margin-bottom: 20px;
}

.btn-styled {
    align-self: flex-start;
    position: absolute;
    right: 90px;
    top: 10px;
}

.inner-detail-sub-head{
    margin-bottom: 10px;
    text-align: start;
    padding: 20px;
}
.table-wrapper {
    border: solid 2px black;
    width: 40%;
    margin: 0 auto;
    box-shadow: 0px -8px 40px rgb(129, 100, 233);
    
}

.table-inner-detail {
    display: flex;
    justify-content: center;
    padding: 10px;
    text-align: start;
    position: relative;
    padding-bottom: 50px;
}

span {
    margin-right: 10px;
}

.table-inner-detail ul {
    margin-left: 10px;
}

.table-inner-detail ul li {
    list-style-type: none;
    margin: 10px;
}

.inner-title {
    background-color: rgb(94, 94, 235);
    width: 100%;
    margin: 0 auto;
    padding: 30px;
    margin-bottom: 20px;
    border: solid 2px black;
    text-align: start;
}


/* media query for snmall screen */
/* ===================================== */
@media screen and (max-width: 960px) {
    .last-message {
        margin-top: 20px;
        margin-bottom: 40px;
        padding: 20px;
    }
}