.main-image-wrapper {
    /* justify-content: center; */
    /* display: flex; */
    padding-top: 50px;
    padding-bottom: 20px;
    background-color: #f2f2f2;
    overflow: hidden;
    /* width: 100%; */

}

.first-image-holder img,
.third-image-holder img,
.fourth-image-holder img,
.second-image-holder img{
    height: 200px;
    margin: 20px;
    border-radius: 20px;
}

.image-holder {
    text-align: center;

}

.image-holder h1 {
    font-size: 1.22em;
}

.name-wrapper {
  margin-left: 20px;
}

.slider-track{
    margin: 10px;
    display: flex;

}

/* media query for snmall screen */
/* ===================================== */
@media screen and (max-width: 960px) {
    .first-image-holder img,
    .third-image-holder img,
    .fourth-image-holder img,
    .second-image-holder img{
        height: 100px;
        margin: 5px;
        margin-top: 20px;
        margin-right: 20px;
        border-radius: 20px;
        padding: 20px;
    }

    .image-holder {
        text-align: center;
        width: 30%;
    }

    .image-holder h1 {
        font-size: 8px;
        display: none;
    }

}