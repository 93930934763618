.course-content-main-wrapper {
    /* position: relative; */
    height: 90vh;
    display: flex;
    width: 100%;
    /* grid-template-columns: repeat(3, 50px); */
    justify-content: center;
    margin-bottom: 80px;
    gap: 10px;
}

.box {
    /* width: 100%; */
    height: 100%;            
    /* position: absolute;
    top: 0;
    left: 95px; */
    /* margin-left: 30px; */
    position: relative;
    background-color: #00d9b6;
    border: solid 2px rgb(56, 53, 53);
}

.right-boxes, .middle-boxes, .left-boxes {
    width: 30%;
}

.box-two {
    /* width: 100%; */
    height: 100%;            
    /* position: absolute;
    top: 0; */
    background-color: #00d9b6;
    /* z-index: 9; */
    /* left: 500px; */
    margin-right: 10px;
    position: relative;
    border: solid 2px rgb(56, 53, 53);

}

.box-three {
    /* width: 100%; */
    height: 100%;            
    /* position: absolute; */
    background-color: #00d9b6;
    /* left: 900px; */
    /* z-index: 9; */
    /* margin-left: 50px; */
    position: relative;
    border: solid 2px rgb(56, 53, 53);
}

.stack-top{
    background-color: #29465b;
    /* z-index: 9; */
    /* margin: 20px; */
    color: #fff;
    position: relative;
    
}

.stack-top-second {
    background-color: #29465b;
    /* margin: 20px; */
    color: #fff;
    /* position: relative; */
}

.box ul, .box-two ul, .box-three ul{
    padding: 10px;
    margin-top: 10px;
    /* margin-left: 30px; */
    text-align: start;
}

.stack-top-second ul li, .stack-top ul li{
 list-style-type: none;
}

.stack-top-second h1, .stack-top h1{
    margin-top: 10px;
    margin-left: 25px;
}

.image-wrapper {
    background-color: #00d9b6;
    padding: 20px;
}

.btn-content {
    background: #fff!important;
    border-radius: 20px !important;
    color: black!important;
    text-align: center;
    margin-bottom: 20px !important;
    font-weight: bold;
}

.btn-wrapper-content {
    border-radius:25px;
    margin-bottom: 45px;
    color: rgb(15, 15, 25);
    font-size: 0.8em;
    font-family: AlmarenaBold, sans-serif;
    padding: 10px 20px;
    margin-left: 15px;
    margin-top: 30px;
    position: absolute;
    bottom: -40px;
}

.btn-middle, .btn-first {
    /* margin-top: 70px; */
}

/* media query for snmall screen */
/* ===================================== */
@media screen and (max-width: 960px) {
    .course-content-main-wrapper {
        /* position: relative; */
        height: 90%;
        display: flex;
        flex-direction: column;
        /* grid-template-columns: repeat(3, 50px); */
        justify-content: center;
        /* margin-bottom: 50%; */
        /* margin-top: 50%; */
        /* gap: 10%; */
    }
    .box-three {
        height: 100%;
        /* margin-top: 20%; */
        /* margin: 20px; */
        /* display: none; */
        /* padding: 20px; */
    }
    
    .box,.box-two, .box-three {
        /* margin-bottom: 20%; */
        /* margin-top: 20%; */
        padding: 20px;
        height: 100%;
        /* margin: 20px; */
        position:static;
        width: 100%;
        margin-left: 20px;
        margin-right: 5px;
    }
  
    .btn-wrapper-content {
        
        margin-left: 5px;
        margin-top: 43px;
        top: -90px;
        position: static;

        
    }
    .right-boxes, .middle-boxes, .left-boxes {
        width: 90%;
    }
}