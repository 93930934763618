.main-title {
    text-align: center;
}

.main-image-wrapper-endorsed {
    /* justify-content: center; */
    display: flex;
    padding-top: 50px;
    padding-bottom: 20px;
    /* background-color: #f2f2f2; */
    overflow: hidden;
    /* width: 100%; */

}

.first-image-holder-endorsed img,
.third-image-holder-endorsed img,
.fourth-image-holder-endorsed img,
.second-image-holder-endorsed img{
    height: 60px;
    width: 200px;
    margin: 10px;
    border-radius: 20px;
}

.title-holder-wrapper {
    /* background-color: #f2f2f2; */
    text-align: center;
}


.main-endorsed-wrapper ul{
    display: flex;
    justify-content: space-around;
    margin: 20px;
}

.main-endorsed-wrapper ul li {
    list-style-type: none;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.main-endorsed-wrapper img {
    width: 120px;
    height: 60px;
}

/* media query for snmall screen */
/* ===================================== */
@media screen and (max-width: 960px) {
    .main-image-wrapper-endorsed{
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .first-image-holder-endorsed img,
    .third-image-holder-endorsed img,
    .fourth-image-holder-endorsed img,
    .second-image-holder-endorsed img {
    height: 40px;
    width: 150px;
    margin: 10px;
    border-radius: 20px;
}
}