.video-setionmainwrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-bottom: 70px;
    font-family: font1!important;
}

.title-wrapper {
    margin-top: 4%;
    margin-bottom: 20px;
    text-align: center;
    padding: 10px;
}

.message-wrapper {
    width: 50%;
    margin-bottom: 3%;
}

/* media query for snmall screen */
/* ===================================== */
@media screen and (max-width: 960px) {

    .video-setionmainwrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-bottom: 70px;
        font-family: font1!important;
    }

    .video-wrapper {
        display: none;
    }

    .message-wrapper {
        width: 70%;
        margin-bottom: 3%;
    }
}