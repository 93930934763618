.navbar {
    height: 100px;
    display: flex;
    justify-content: flex-start;
    align-items: center;

}

.menu-bars {
    font-size: 1.3rem;
    background: none;
}

.nav-menu {
    background-color: #f2f2f2;
    width: 250px;
    height: 50vh;
    display: flex;
    flex-direction: row;
    justify-content: center;
    position: fixed;
    top: 0;
    left: -100%;
    transition: 850ms;
    z-index: 1;
}

.nav-menu.active {
    left: 0;
    transition: 350ms;
}

.nav-text {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 8px 0px 8px 13px;
    list-style: none;
    height: 60px;
}

.nav-text a {
    text-decoration: none;
    color: #f5f5f5;
    font-size: 18px;
    width: 95%;
    height: 100%;
    display: flex;
    align-items: center;
    padding: 0 10px;
    border-radius: 4px;
}

.nav-text a:hover {
    background-color: #1a83ff;
}

.nav-menu-items {
    width: 100%;
}

.navbar-toggle {
    background-color: #f2f2f2;
    width: 100%;
    height: 106px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding-left: 10px;
    border-bottom: rgb(109, 105, 106) 1px solid;
}

span {
    margin-left: 16px;
}