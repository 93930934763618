.how-admision-main-wrapper {
    text-align: center;
    background-color: #29465b;
    padding: 30px;
    padding-bottom: 50px;
    color: #fff;
    font-family: font1!important;
}

.steps-wrapper {
    display: flex;
    justify-content: flex-start;
    gap: 30px;
    margin-top: 30px;
    margin-left: 10%;
}

.icon-wrapper {
    margin-top: 22px;
}

.steps-wrapper li {
    text-align: start;
    list-style-type: none;
    margin-top: 23px;
    font-size: 1.54em;
}

.number-wrapper {
    font-size: 50px;
    margin-top: 18px;
    font-weight: bold;
}

/* media query for snmall screen */
/* ===================================== */
@media screen and (max-width: 960px) {
    .icon-wrapper {
        display: none;
    }

    .how-admision-main-wrapper {
        margin: 10px;
    }
}