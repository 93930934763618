@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap');

.becomemainwrapper {
    background-color: #29465b;
    font-family: font3!important;
}

.titlehead {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 5%;
    gap:10px;
    font-weight: 600; 
}

.scrolling-words-box {
  color: #84bb29;
  font-size: 3.1875em;
}

.write-up-wrapper {
    display: flex;
    justify-content: center;
    gap: 5px;
    width: 50%;
}

.coding-wrapper {
    color: #84bb29;
}

.scrolling-words-box  {
    position: relative;
    /* top: 50%;   */
    /* width: 1em; */
    /* margin: 0 auto; */
    border-right: 2px solid rgba(255,255,255,.75);
    font-size: 280%;
    text-align: center;
    white-space: nowrap;
    overflow: hidden;
    transform: translateY(6%);  
}

/* Animation */
.typewriter-text{
    animation: typewriter 4s steps(44) 1s infinite normal both,
               blinkTextCursor 500ms steps(44) infinite normal;
  }
  @keyframes typewriter{
    from{width: 0;}
    to{width: 5em;}
  }
  @keyframes blinkTextCursor{
    from{border-right-color: rgba(255,255,255,.75);}
    to{border-right-color: transparent;}
  }


.title-one {
    font-size: 3.1875em;
    line-height: 70px;
    color: #fff;
}

.title-two {
    color: #84bb29;
    font-size: 3.1875em;
    letter-spacing: -0.01em;
    line-height: 70px;
}

.sroll-up-text {
    color: #84bb29;
    font-size: 3.1875em;
    letter-spacing: -0.01em;
    line-height: 70px;
}


.detail-message {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: #fff;
}

.detail-message h4 {
    margin-bottom: 5px;
    font-size: 1.375em;
    font-weight: 10px;
    line-height: 32px;
   font-family: 'Open Sans', sans-serif;
}

.btn-wrapper {
    padding-bottom: 35px;
    margin-top: 20px;
}

.btn-navbar {
    background: #84bb29!important;
    border-radius: 20px !important;
    color: #fff!important;
    text-align: center;
    margin-bottom: 20px !important;
}

/* media query for snmall screen */
/* ===================================== */
@media screen and (max-width: 960px) {
    .becomemainwrapper {
        background-color: #29465b;
        font-family: font3!important;
     
        /* width: 100vw; */
    }

    .titlehead {
        display: flex;
        flex-direction: column;
        margin-top: 10px;
        padding-top: 10%;
        gap:10px;
        font-weight: 600; 
    }

      .title-one {
        font-size: 2.1875em;
        line-height: 0px;
        color: #fff;
        
    }
    .write-up-wrapper {
        display: flex;
        flex-direction: column;
        gap: 5px;
    }
}