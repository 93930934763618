@import url('https://fonts.googleapis.com/css2?family=Merriweather:wght@700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');

.mainnavbar {
    background-color: #fff;
    display: flex;
    justify-content: space-evenly;
    padding-top: 35px;
    font-size: 1em;
    color: #000;
    line-height: 15px;
}

.wrapper-ul ul{
    background-color: #fff;
    display: flex;
    justify-content: space-evenly;
    gap: 80px;
}
.mainnavbar ul li a {
    text-decoration: none;
    color: #fff;
    margin-top: 20px !important;
    font-family: 'Roboto', sans-serif;
}

.mainnavbar ul li {
    cursor: pointer;
    list-style-type: none;
    font-family: font1!important;
    
}

.btn-navbar {
    background: #84bb29!important;
    border-radius: 10px !important;
    color: #fff!important;
    text-align: center;
    margin-bottom: 20px !important;
    bottom: 10px;
}



.logo a{
    bottom: 10px;
}

.logo img {
    width: 140px;

}
.nav-link-group {
    display: flex;
    justify-content: flex-end;
    background-color: brown;
    width: 30%;
}

.nav-link-group ul li{
    margin-right: 50px;
}

.hamburger-btn {
    display: none;
}

/* media query for snmall screen */
/* ===================================== */
@media screen and (max-width: 960px) {

    .mainnavbar {
        display: flex;
        /* width: 90vw; */
        justify-content: space-around;
        align-items: center;
        padding-top: 15px;
        padding-bottom: 15px;
        font-size: 1em;
        color: #000;
        line-height: 15px;
    }
    .wrapper-ul {
        display: none;
    }

    .main-nav-btn {
        display: none !important;
    }

    .logo img {
        width: 40px;
    
    }
    .hamburger-btn {
        display: block;
        margin-left: 50px;
    }
}